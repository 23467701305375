import React from 'react';
import './App.css';
import AppInput from "./components/app-input/app-input";
import {dictionary} from "./utilities/constants";
import AppHeader from "./components/app-header/app-header";

function App() {
  return (
      <div className={"flex flex-col"}>
          <AppHeader/>
          <AppInput dictionary={dictionary}/>
      </div>
  );
}

export default App;

import React from "react";

const AppHeader = () => {
    return <nav className="flex items-center justify-between flex-wrap p-6 w-full z-10 pin-t bg-gray-700">
        <div className="flex items-center flex-no-shrink text-white mr-6">
            <a className="text-white no-underline hover:text-white hover:no-underline" href="#">
                <span className="text-2xl pl-2"><i className="em em-grinning"></i> EFEX Challenge</span>
            </a>
        </div>
    </nav>
}

export default AppHeader;
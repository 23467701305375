import {useState} from "react";
import Teaxtarea from "../ui/Teaxtarea";

const AppInput = ({dictionary = {}}) => {

    const [text, setText] = useState('')

    function handleChange(e: React.ChangeEvent<HTMLTextAreaElement>) {
        setText(e.target.value);
        const words = text.split(" ");
        const dictionaryWords = Object.getOwnPropertyNames(dictionary);
        words.forEach((d, i) => {
            const n = dictionaryWords.indexOf(d);
            if (n !== -1) {
                words[i] = dictionary[dictionaryWords[n] as keyof typeof dictionary];
                setText(words.join(" ")+" ");
            }
            return true;
        });
    }

    return <div className="w-full flex justify-center items-center">
        <Teaxtarea
            rows={10}
            id="large-input"
            data-testid="text-input"
            value={text}
            onInput={handleChange}/>
    </div>
}

export default AppInput;
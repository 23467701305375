export const dictionary = {
    'helo': 'hello',
    'wrld': 'world',
    'recieve': 'receive',
    'occured': 'occurred',
    'seperated': 'separated',
    'definately': 'definitely',
    'untill': 'until',
    'apointment': 'appointment',
    'adress': 'address',
    'becuase': 'because',
    'brocolli': 'broccoli',
    'calender': 'calendar',
    'catergory': 'category',
    'comfirm': 'confirm',
    'dissapear': 'disappear',
    'embarras': 'embarrass',
    'excersize': 'exercise',
    'favour': 'favor',
    'fith': 'fifth',
    'freind': 'friend',
    'goverment': 'government',
    'humerous': 'humorous',
    'inocent': 'innocent',
    'intrested': 'interested',
    'judgement': 'judgment',
    'liase': 'liaise',
    'minumum': 'minimum',
    'neccessary': 'necessary',
    'ocassion': 'occasion',
    'perseverence': 'perseverance',
    'refered': 'referred',
    'religous': 'religious',
    'resistence': 'resistance',
    'seperately': 'separately',
    'succesful': 'successful',
    'tommorrow': 'tomorrow',
    'writting': 'writing',
    'yatch': 'yacht'
};
